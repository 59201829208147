(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("crypto"), require("object-hash"), require("axios"), require("node-forge"), require("buffer/"));
	else if(typeof define === 'function' && define.amd)
		define(["crypto", "object-hash", "axios", "node-forge", "buffer/"], factory);
	else if(typeof exports === 'object')
		exports["@cesarvm/firmafiel-siippg"] = factory(require("crypto"), require("object-hash"), require("axios"), require("node-forge"), require("buffer/"));
	else
		root["@cesarvm/firmafiel-siippg"] = factory(root["crypto"], root["object-hash"], root["axios"], root["node-forge"], root["buffer/"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 